import my1 from "../img/my1.png";
import my2 from "../img/my2.png";
import self from "../img/rokonz.png";

/* Hi there! Thanks for checking out my portfolio template. Be sure to read the comments to get a better understanding of
how to make this template work best for you! */

//export let colors = ["rgb(0,0,1)", "rgb(166,104,255)"];
export let colors = ["rgb(0,255,164)", "rgb(166,104,255)"];
/*
I highly recommend using a gradient generator like https://gradientgenerator.paytonpierce.dev/ to generate a pair of colors that you like.
These colors will be used to style your name on the homepage, the background of your picture, and some other accents throughout
the site.
 */

/* 
This variable will change the layout of the website from multipage to single, scrollable page
*/
export let singlePage = false;

/*
So let's get started! Some of the info below is pretty self-explanatory, like 'firstName' and 'bio'. I'll try to explain anything
that might not be obvious right off the bat :) I recommend looking at the template example live using "npm start" to get an idea
of what each of the values mean.
 */

export const info = {
    firstName: "Rokon",
    lastName: "Zaz",
    initials: "🙎🏻‍♂️", // the example uses first and last, but feel free to use three or more if you like.
    position: "a Sr. Software Engineer",
    selfPortrait: self, // don't change this unless you want to name your self-portrait in the "img" folder something else!
    gradient: `-webkit-linear-gradient(135deg, ${colors})`, // don't change this either
    baseColor: colors[0],
    miniBio: [ // these are just some "tidbits" about yourself. You can look at mine https://paytonjewell.github.io/#/ for an example if you'd like
        {
            emoji: '🥤',
            text: 'fueled by iced coffee'
        },
        {
            emoji: "👨🏽‍💻",
            text: "Sr. Software Engineer at Nexdecade Technology (Pvt.) Ltd."
        },
        {
            emoji: "📨",
            text: "rokonz.dev@gmail.com"
        },
        {
            emoji: '🏏',
            text: 'profession cricketer'
        },
        {
            emoji: '🏍️',
            text: 'bike racer'
        },
        {
            emoji: '📍',
            text: 'based in the Bangladesh'
        },
    ],
    socials: [
        {
            link: "https://www.facebook.com/rokonz.official/",
            icon: 'fa fa-facebook-square',
            label: 'facebook'
        },
        {
            link: "https://www.instagram.com/zazbroh/",
            icon: 'fa fa-instagram',
            label: 'instagram'
        },
        {
            link: "https://github.com/rokonzaz",
            icon: "fa fa-code-fork",
            label: 'github'
        },
        {
            link: "https://www.linkedin.com/in/rokonz/",
            icon: "fa fa-linkedin-square",
            label: 'linkedin'
        },
       
// Feel free to remove any of these that you don't have. I'll write an FAQ on how to add new ones later, let me know if you have a request for an icon!
// Just change the links so that they lead to your social profiles.

    ],
    bio: "Hello! I'm Rokon Zaman. I'm a Sr. Software Engineer at Nexdecade Technology (Pvt.) Ltd. I studied CSE at Stamford University. Having experience of more than 14 years on Software Development area and still I’m always curious about new technologies and glad to handle challenges at my work place.",
    skills:
        {
            proficientWith: ['php', 'laravel', 'ott', 'javascript', 'jQuery', 'nodejs', 'react', 'Asp.net', 'C#', 'angular', 'mysql', 'git', 'bootstrap', 'html5', 'css3', 'figma', 'ruby on rails', 'codeIgniter', 'wordpress', 'drupal', 'magento', 'joomla', 'bitbucket', 'jira'],
            exposedTo: ['redis', 'gcp', 'aws', 'tizen', 'webOs', 'kubernetes', 'webAssembly', 'rust']
        }
    ,
    hobbies: [
        {
            label: 'bike and car riding',
            emoji: '🏍️🚘'
        },
        {
            label: 'playing cricket',
            emoji: '🏏'
        },
        {
            label: 'marathon',
            emoji: '🏃'
        },
        {
            label: 'movies',
            emoji: '🎥'
        }
// Same as above, change the emojis to match / relate to your hobbies or interests.
// You can also remove the emojis if you'd like, I just think they look cute :P
    ],
    portfolio: [ // This is where your portfolio projects will be detailed
        {
            title: "Project 1",
            live: "https://www.toffeelive.com/", //this should be a link to the live version of your project, think github pages, netlify, heroku, etc. Or your own domain, if you have it.
            source: "https://www.toffeelive.com/", // this should be a link to the **repository** of the project, where the code is hosted.
            image: my1
        },
        {
            title: "Project 2",
            live: "https://www.tsports.com/",
            source: "https://www.tsports.com/",
            image: my2
        }
    ]
}